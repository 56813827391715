/* Navbar.css */

/* General Navbar Styles */
.navbar {
  background-color: #000000; /* Dark teal */
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1000;
}

/* Container inside the navbar */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

/* Logo Styles */
.logo img {
  height: 40px;
}

/* Navigation Links (Desktop) */
.nav-links {
  display: flex;
  align-items: center;
  list-style: none;
}

/* Individual Nav Items */
.nav-links li {
  margin-left: 20px;
}

/* Nav Links */
.nav-links a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

/* Hover Effect on Links */
.nav-links a:hover {
  color: #ffccbc; /* Light red */
}

/* Menu Icon (Hamburger) */
.menu-icon {
  display: none;
  font-size: 28px;
  color: #fff;
  cursor: pointer;
}

/* Adjust icon size and color */
.menu-icon svg {
  fill: #fff;
}

/* Overlay Styles */
.overlay {
  position: fixed;
  top: 0; /* Adjusted to cover full screen */
  left: 0;
  width: 100%;
  height: 100vh; /* Cover entire viewport */
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Show menu icon and hide nav links */
  .menu-icon {
    display: block;
  }

  /* Hide desktop nav links */
  .nav-links {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #6e6f6f;
    flex-direction: column;
    align-items: center;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 999;
    margin: 0;
    padding: 0;
    overflow-y: auto;
  }

  /* When menu is open */
  .nav-links.mobile {
    transform: translateX(0);
  }

  /* Navigation Items */
  .nav-links li {
    margin: 20px 0;
    width: 100%;
    text-align: center;
  }

  /* Navigation Links */
  .nav-links a {
    display: block;
    width: 100%;
    padding: 15px 0;
  }

  /* Hide desktop nav links */
  .nav-links:not(.mobile) {
    display: none;
  }
}